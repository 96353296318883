import React, { FC } from 'react';
import './faq.scss';

interface FaqProps {}
const Faq: FC<FaqProps> = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
  <div className='faq'>
    <div className='subtitle'>Booking Information</div>
    To submit a request, please read all of the following information, then fill out my <a className="underline" href="https://thekathylee.com/booking">booking form</a> and allow 3-7 days for a response.
    <div className="spacer"></div>

    <div className="subtitle">Deposit</div>
    A <span className='bold'>non-refundable</span> deposit ($150-300, depending on the size of the tattoo) is required to secure your appointment.
    <div className="spacer"></div>

    <div className="subtitle">Pricing </div>
    My minimum is <span className="bold">$200</span> per tattoo.<br/>
    <div className="spacer"></div>
    If you have a budget, please include that information in the initial request so I can assess feasability and so it can be taken into account in the design process.
    <div className="spacer"></div>

    <div className="subtitle">Booking Process </div>
    Here is an overview of the process:
    <div className="spacer"></div>
    <ul>
    <li> When you send a request, you'll receive an automated response. </li>
    <li> I'll review your request and email you with any clarifying questions and to ask about specific dates for our potential appointment. </li>
    <li> If it's a custom, I will start working on the design <span className='bold'>AFTER</span> the appointment is confirmed and the deposit is paid. </li>
    <li> If you'd like a ballpark estimate of time and cost before paying your deposit, please provide: </li>
    &emsp; - a photo of the placement with the tattoo area circled  <br/>
    &emsp; - all element you'd like included as well as any specific requests  <br/>
    &emsp; - relative size in inches  <br/>
    <span className='bold'>Time and price estimates may change if there are major changes in the request details.</span>
    <li> Once I finish the design, I'll send it to you. <br/>
    At the latest, I'll get the design to you a few days before our appointment. At this point I will provide a price and loose time estimates. Major changes may result in a redrawing fee (amount of deposit). </li>
    </ul>
    <div className="spacer"></div>

    <div className="subtitle">Rescheduling</div>
    Things happen - <span className='bold'>one</span> reschedule is allowed if you notify me at least 48 hours before your appointment. If you need to reschedule more than once, you will need to put down a new deposit to secure your new appointment.
    
    <div className="spacer"></div>
    Failure to notify me with sufficient notice will result in your deposit being forfeited and a new deposit will be required to secure a new appointment.
    <div className="spacer"></div>

    <div className="subtitle">Payment</div>
    I accept cash, Zelle, or card. Thank you!
    <div className="spacer"></div>

    Tattoo prep and aftercare instructions can be found <a className="underline" href="https://thekathylee.com/aftercare">here.</a>
    <div className="spacer"></div>

    You can contact me with any questions or concerns at: <a href="mailto:booking.kathylee@gmail.com">booking.kathylee@gmail.com</a>
    <div className="spacer-lg"></div>

  </div>
  );
}

export default Faq;
